export default class DataTableTab {
  key!: string
  title!: string

  setKey(key: string): this {
    this.key = key
    return this
  }

  setTitle(title: string): this {
    this.title = title
    return this
  }
}