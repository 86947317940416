








import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import User from '@/shared/modules/user/user.model'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import { AdminRoutes } from '@/shared/router/admin'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import UserService from '@/shared/modules/user/user.service'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import permissions from '@/shared/helpers/permissions.helper'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import DataTableTab from '@/shared/classes/components/data-table/data-table-tab';

@Component({
  components: { DataTable, PageHeader },
  methods: {
    __,
  }
})
export default class UsersIndex extends Vue {
  table = new DataTableBase()
    .setModel(User)
    .setEndpoint('users')
    // .setMultipleCheckbox(true)
    // .setQuickAccess(UserQuickAccess)
    .setActiveTab(1)
    .setTabs([
      new DataTableTab()
        .setKey('all')
        .setTitle(__('admin.views.users.index.table.tabs.all')),
      new DataTableTab()
        .setKey('active')
        .setTitle(__('admin.views.users.index.table.tabs.active')),
      new DataTableTab()
        .setKey('inactive')
        .setTitle(__('admin.views.users.index.table.tabs.inactive')),
    ])
    .setHeaders([
      new DataTableHeader()
        .setKey('full_name')
        .setEntryKey('fullName')
        .setText(__('admin.views.users.index.table.columns.full-name')),
      new DataTableHeader()
        .setEntryKey('email')
        .setText(__('admin.views.users.index.table.columns.email')),
      new DataTableHeader()
        .setKey('role.name')
        .setText(__('admin.views.users.index.table.columns.role'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('company.name')
        .setText(__('admin.views.users.index.table.columns.company'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('position.name')
        .setText(__('admin.views.users.index.table.columns.position'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('status.name')
        .setText(__('admin.views.users.index.table.columns.status'))
        .setSortable(false),
    ])
    .setFilters([
      new DataTableFilter()
        .setOperator(FilterOperators.in)
        .setKeyIsPrimary()
        .setField(
          new SelectField()
            .isMultiple()
            .setKey('role.uuid')
            .setTitle(__('admin.views.users.index.table.filters.role'))
            .loadItems({ endpoint: '/roles', value: 'uuid', title: 'name' })
        ),
      new DataTableFilter()
        .setOperator(FilterOperators.equals)
        .setKeyIsPrimary()
        .setField(
          new SearchableField()
            .setKey('company.uuid')
            .setTitle(__('admin.views.users.index.table.filters.company'))
            .loadItems({ endpoint: '/companies', value: 'uuid', title: 'name', perPage: 20 }),
        ),
      new DataTableFilter()
        .setOperator(FilterOperators.in)
        .setKeyIsPrimary()
        .setField(
          new SelectField()
            .isMultiple()
            .setKey('position.uuid')
            .setTitle(__('admin.views.users.index.table.filters.position'))
            .loadItems({ endpoint: '/positions', value: 'uuid', title: 'name' })
        )
    ])
    .setActions([
      new DataTableAction()
        .setIcon('mdi-login')
        .setTitle(__('admin.views.users.index.table.actions.go-in'))
        .setPermissions([ permissions.admin.users.loginLike ])
        .setAction((item: User) => UserService.loginLike(item)),
      new EditAction(),
      new DeleteAction()
        .setTitle(__('admin.views.users.index.table.actions.deactivate.button'))
        .setPermissions([ permissions.admin.users.deactivate ])
        .setAction((item: User) => this.$store.dispatch(GlobalActions.showDialog, {
          show: true,
          component: AreYouSureDialog,
          meta: {
            title: __('admin.views.users.index.table.actions.deactivate.title'),
            text: __('admin.views.users.index.table.actions.deactivate.text', { fullName: item.fullName }),
            onYes: () => UserService.deactivate(item.uuid)
              .then(() => this.tableRef.refresh())
          }
        })),
    ])

  navigateToCreate(): void {
    this.$router.push({ name: AdminRoutes.usersCreate })
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
